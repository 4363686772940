// components/UploadResultView.js
import React, { useState, useEffect } from 'react';
import { Tabs, List, Button } from 'antd';
import { CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import { useFileContext } from '../contexts/FileContext';
import { copyTextToClipboard } from '../utils/uploadService';
const { TabPane } = Tabs;

const UploadResultView = ({ onFormatChange }) => {
  const { files, retryUpload } = useFileContext();
  const [activeTab, setActiveTab] = useState('URL');

  useEffect(() => {
    onFormatChange(activeTab);
  }, [activeTab, onFormatChange]);

  const getFormattedText = (url, format) => {
    switch (format) {
      case 'BBCode':
        return `[img]${url}[/img]`;
      case 'HTML':
        return `<img src="${url}" alt="uploaded image" />`;
      case 'Markdown':
        return `![uploaded image](${url})`;
      default:
        return url;
    }
  };
  

  const renderListItem = (item, format) => (
    <List.Item
      style={{ display: 'flex', alignItems: 'center' }}
      actions={[
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {item.status === 'error' ? (
            <Button 
              icon={<ReloadOutlined />} 
              onClick={() => retryUpload(item.file)}
              type="link"
            >
              Retry
            </Button>
          ) : (
            <Button 
              icon={<CopyOutlined />} 
              onClick={() => copyTextToClipboard(getFormattedText(item.url, format))}
              type="link"
            >
              Copy
            </Button>
          )}
        </div>
      ]}
    >
      <div style={{ wordBreak: 'break-all', flex: 1 }}>
        {item.status === 'error' ? (
          <span style={{ color: 'red' }}>{item.error || 'Upload failed'}</span>
        ) : (
          getFormattedText(item.url, format)
        )}
      </div>
    </List.Item>
  );

  return (
    <div style={{ marginTop: 16 }}>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        {['URL', 'BBCode', 'HTML', 'Markdown'].map(format => (
          <TabPane tab={format} key={format}>
            <List
              size="small"
              bordered
              dataSource={files}
              renderItem={item => renderListItem(item, format)}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default UploadResultView;