// components/FileList.js
import React from 'react';
import { List, Card, Button, Progress, Typography } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useFileContext } from '../contexts/FileContext';

const { Text } = Typography;

const FileList = () => {
  const { files, removeFile } = useFileContext();

  const handlePreview = (url) => {
    window.open(url, '_blank');
  };

  return (
    <List
      grid={{
        gutter: 8,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 6,
      }}
      dataSource={files}
      renderItem={({ file, status, progress, error, url }) => (
        <List.Item style={{ marginBottom: 8 }}>
          <Card
            hoverable
            style={{ 
              width: '100%', 
              minWidth: 250, 
              maxWidth: 350 
            }}
            cover={
              file.type.startsWith('image') ? (
                <img
                  alt={file.name}
                  src={URL.createObjectURL(file)}
                  style={{ height: 200, objectFit: 'cover' }}
                />
              ) : (
                <video
                  src={URL.createObjectURL(file)}
                  style={{ height: 200, objectFit: 'cover' }}
                />
              )
            }
            actions={[
              <Button 
                icon={<DeleteOutlined />} 
                onClick={() => removeFile(file)}
              >
                Delete
              </Button>,
              status === 'success' && (
                <Button 
                  icon={<EyeOutlined />} 
                  onClick={() => handlePreview(url)}
                >
                  Preview
                </Button>
              )
            ].filter(Boolean)}
          >
            <Card.Meta 
              title={file.name} 
              description={
                <>
                  <Text>{`${(file.size / 1024 / 1024).toFixed(2)} MB`}</Text>
                  {status === 'uploading' && (
                    <Progress percent={progress} size="small" />
                  )}
                  {status === 'error' && (
                    <Text type="danger" style={{ display: 'block' }}>
                      {error || 'Upload failed'}
                    </Text>
                  )}
                </>
              }
            />
          </Card>
        </List.Item>
      )}
    />
  );
};

export default FileList;