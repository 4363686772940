import React, { useState, useCallback } from 'react';
import { Layout, Typography, Switch, ConfigProvider, theme, message, Space } from 'antd';
import UploadZone from './components/UploadZone';
import FileList from './components/FileList';
import UploadButton from './components/UploadButton';
import UploadResultView from './components/UploadResultView';
import { FileProvider, useFileContext } from './contexts/FileContext';
import { copyTextToClipboard, uploadFiles } from './utils/uploadService';

const { Content, Header } = Layout;
const { Title } = Typography;

const AppContent = () => {
  const { files, updateFileStatus } = useFileContext();
  const [uploadStatus, setUploadStatus] = useState('not_started');
  const [selectedFormat, setSelectedFormat] = useState('URL');

  const handleUploadComplete = useCallback(() => {
    setUploadStatus('completed');
  }, []);

  const handleFormatChange = useCallback((format) => {
    setSelectedFormat(format);
  }, []);

  const retryUpload = useCallback(async (file) => {
    setUploadStatus('in_progress');
    try {
      await uploadFiles([{ file }], updateFileStatus);
      message.success(`File ${file.name} uploaded successfully!`);
      if (files.every(f => f.status === 'success')) {
        setUploadStatus('completed');
      }
    } catch (error) {
      console.error('Retry upload error:', error);
      message.error(`Failed to upload ${file.name}: ${error.message}`);
      setUploadStatus('completed');
    }
  }, [files, updateFileStatus]);

  const getFormattedText = useCallback((url, format) => {
    switch (format) {
      case 'BBCode':
        return `[img]${url}[/img]`;
      case 'HTML':
        return `<img src="${url}" alt="uploaded image" />`;
      case 'Markdown':
        return `![uploaded image](${url})`;
      default:
        return url;
    }
  }, []);

  const handleCopyAll = useCallback((format) => {
    const textToCopy = files
      .filter(file => file.status === 'success')
      .map(file => getFormattedText(file.url, format))
      .join('\n');
      copyTextToClipboard(textToCopy);
  }, [files, getFormattedText]);

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      <UploadZone />
      <FileList />
      {uploadStatus === 'completed' && files.length > 0 && (
        <UploadResultView onFormatChange={handleFormatChange} retryUpload={retryUpload} />
      )}
      <UploadButton
        onUploadComplete={handleUploadComplete}
        uploadStatus={uploadStatus}
        selectedFormat={selectedFormat}
        onCopy={handleCopyAll}
      />
    </Space>
  );
};


const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          padding: 8,
          margin: 8,
        },
      }}
    >
      <FileProvider>
        <Layout style={{ minHeight: '100vh' }}>
          <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 16px' }}>
            <Title level={3} style={{ color: 'white', margin: 0 }}>Image Upload</Title>
            <Switch
              checked={isDarkMode}
              onChange={setIsDarkMode}
              checkedChildren="🌙"
              unCheckedChildren="☀️"
            />
          </Header>
          <Content style={{ padding: '16px', maxWidth: 1200, margin: '0 auto', width: '100%' }}>
            <AppContent />
          </Content>
        </Layout>
      </FileProvider>
    </ConfigProvider>
  );
};

export default App;