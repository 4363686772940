import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter } from "react-router-dom"

import App from './App'

// @ => src: webpack
// 问题: react脚手架隐藏webpack
// 解决一: npm run eject
// 解决二: craco => create-react-app config

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Suspense fallback="loading">
      {/* <Provider store={store}>
        <HashRouter>
          <App/>
        </HashRouter>
      </Provider> */}
       <HashRouter>
          <App/>
        </HashRouter>
    </Suspense>
  // </React.StrictMode>
);
