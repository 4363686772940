import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { message } from 'antd';

// 获取代理配置的域名
const getProxyDomain = () => {
  // 在开发环境中，我们可以使用 process.env.REACT_APP_PROXY
  // 在生产环境中，您需要设置一个环境变量或使用其他方式来配置这个值
  return process.env.REACT_APP_PROXY || 'https://img.1kb.fr';
};

export const uploadFiles = async (files, updateFileStatus) => {
  const proxyDomain = getProxyDomain();
  const batchSize = 5;
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const uploadBatch = async (batch) => {
    const uploadPromises = batch.map(async fileObj => {
      let fileToUpload = fileObj.file;

      // Check if the file is an image and larger than 5MB
      if (fileToUpload.type.startsWith('image/')) {
        const img = new Image();
        img.src = URL.createObjectURL(fileToUpload);
        await new Promise(resolve => img.onload = resolve);

        const needsCompression = fileToUpload.size > 5 * 1024 * 1024 || 
                               img.width > 3840 || 
                               img.height > 3840;

        if (needsCompression) {
          try {
            const options = {
              maxSizeMB: 5,
              maxWidthOrHeight: 3840,
              useWebWorker: true
            };
            fileToUpload = await imageCompression(fileToUpload, options);
            console.log('Image compressed successfully');
          } catch (error) {
            console.error('Error compressing image:', error);
          }
        }
        URL.revokeObjectURL(img.src);
      }

      const formData = new FormData();
      formData.append('file', fileToUpload);

      try {
        const response = await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            updateFileStatus(fileObj.file, 'uploading', percentCompleted);
          },
        });

        console.log('API Response:', response.data);

        if (response.data && response.data[0] && response.data[0].src) {
          const fullUrl = `${proxyDomain}${response.data[0].src}`;
          updateFileStatus(fileObj.file, 'success', 100, null, fullUrl);
          
          // 发送请求访问图片
          try {
            await fetch(fullUrl, { method: 'GET' });
            console.log('Image accessed successfully:', fullUrl);
          } catch (error) {
            console.error('Error accessing image:', error);
          }
          
          return fullUrl;
        } else {

          const errorMessage = `Upload failed: ${response.data?.error ?? 'Unexpected response error'}`;
          updateFileStatus(fileObj.file, 'error', 0, errorMessage, null);
          throw new Error(errorMessage);
        }
      } catch (error) {
        let errorMessage = 'Upload failed';
        if (error.response) {
          errorMessage = `Server error: ${error.response.status}, ${JSON.stringify(error.response.data)}`;
        } else if (error.request) {
          errorMessage = 'No response from server';
        } else {
          errorMessage = error.message;
        }
        console.error('Upload error details:', error);
        updateFileStatus(fileObj.file, 'error', 0, errorMessage);
        throw new Error(errorMessage);
      }
    });

    return Promise.all(uploadPromises);
  };

  const results = [];
  for (let i = 0; i < files.length; i += batchSize) {
    const batch = files.slice(i, i + batchSize);
    const batchResults = await uploadBatch(batch);
    results.push(...batchResults);

    if (i + batchSize < files.length) {
      await delay(1000); // 等待1秒
    }
  }

  return results.filter(url => url);
};


export const copyTextToClipboard = (text) => {
  return new Promise((resolve, reject) => {
    // 首先尝试使用现代的 Clipboard API
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          message.success('Copied to clipboard');
          resolve(true);
        })
        .catch(err => {
          console.warn('Clipboard API failed, falling back to execCommand', err);
          fallbackCopyTextToClipboard(text).then(resolve).catch(reject);
        });
    } else {
      // 如果 Clipboard API 不可用，使用 execCommand 方法
      fallbackCopyTextToClipboard(text).then(resolve).catch(reject);
    }
  });
};

const fallbackCopyTextToClipboard = (text) => {
  return new Promise((resolve, reject) => {
    const originalActiveElement = document.activeElement;
    const textArea = document.createElement("textarea");
    textArea.value = text;
    
    // 优化样式设置
    Object.assign(textArea.style, {
      position: 'fixed',
      top: '-9999px',
      left: '-9999px',
      width: '1px',
      height: '1px',
      padding: '0',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      background: 'transparent',
      opacity: '0',
      zIndex: '-1'
    });
    
    // 创建一个覆盖层来掩盖可能的视觉变化
    const overlay = document.createElement('div');
    Object.assign(overlay.style, {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      background: 'transparent',
      zIndex: '9999'
    });

    document.body.appendChild(overlay);
    document.body.appendChild(textArea);
    
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    
    // 保存原始的视口元标签
    const originalViewport = document.querySelector('meta[name=viewport]');
    const originalViewportContent = originalViewport ? originalViewport.content : '';
    
    // 设置视口以禁止缩放
    const viewport = document.createElement('meta');
    viewport.name = 'viewport';
    viewport.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
    if (originalViewport) {
      originalViewport.parentNode.replaceChild(viewport, originalViewport);
    } else {
      document.head.appendChild(viewport);
    }
    
    textArea.focus();
    textArea.select();
    
    try {
      document.execCommand('copy');
      message.success('Copied to clipboard');
      resolve(true);
    } catch (err) {
      console.error('execCommand error:', err);
      reject(err);
    } finally {
      // 恢复原始状态
      if (originalActiveElement && typeof originalActiveElement.focus === 'function') {
        originalActiveElement.focus();
      }
      
      // 恢复原始视口设置
      if (originalViewport) {
        originalViewport.content = originalViewportContent;
      } else {
        document.head.removeChild(viewport);
      }
      
      window.scrollTo(scrollLeft, scrollTop);
      
      // 使用 requestAnimationFrame 延迟移除元素
      requestAnimationFrame(() => {
        document.body.removeChild(textArea);
        document.body.removeChild(overlay);
      });
    }
  });
};