// components/UploadButton.jsx
import React from 'react';
import { Button, message } from 'antd';
import { UploadOutlined, LoadingOutlined, CopyOutlined } from '@ant-design/icons';
import { useFileContext } from '../contexts/FileContext';
import { uploadFiles } from '../utils/uploadService';

const UploadButton = ({ onUploadComplete, uploadStatus, selectedFormat, onCopy }) => {
  const { files, updateFileStatus } = useFileContext();

  const handleUpload = async () => {
    const filesToUpload = files.filter(f => f.status !== 'success');
    if (filesToUpload.length === 0) {
      message.info('No new files to upload.');
      return;
    }

    try {
      await uploadFiles(filesToUpload, updateFileStatus);
      message.success('All files uploaded successfully!');
      onUploadComplete();
    } catch (error) {
      console.error('Upload error:', error);
      message.error(`Upload failed: ${error.message}`);
    }
  };

  const handleCopy = () => {
    onCopy(selectedFormat);
  };

  let icon;
  let onClick;
  if (uploadStatus === 'not_started') {
    icon = <UploadOutlined />;
    onClick = handleUpload;
  } else if (uploadStatus === 'in_progress') {
    icon = <LoadingOutlined />;
    onClick = () => {};
  } else {
    icon = <CopyOutlined />;
    onClick = handleCopy;
  }

  return (
    <Button
      type="primary"
      shape="circle"
      icon={icon}
      onClick={onClick}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '60px',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
      }}
      disabled={uploadStatus === 'in_progress' || (files.length === 0)}
    />
  );
};

export default UploadButton;