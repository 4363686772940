// contexts/FileContext.js
import React, { createContext, useState, useContext } from 'react';
import { message } from 'antd';

const FileContext = createContext();

export const FileProvider = ({ children }) => {
  const [files, setFiles] = useState([]);

  const addFiles = (newFiles) => {
    const filesToAdd = newFiles.filter(newFile => {
      const isMP4 = newFile.name.toLowerCase().endsWith('.mp4');
      if (isMP4 && newFile.size > 5 * 1024 * 1024) {
        message.error(`MP4 file ${newFile.name} is too large. Max size for MP4 is 5MB.`);
        return false;
      }
      if (files.some(f => f.file.name === newFile.name && f.file.size === newFile.size)) {
        message.info(`File ${newFile.name} is already in the upload list.`);
        return false;
      }
      return true;
    });

    const filesWithStatus = filesToAdd.map(file => ({
      file,
      status: 'pending',
      progress: 0,
      error: null,
      url: null
    }));

    setFiles(prevFiles => [...prevFiles, ...filesWithStatus]);
    
    if (filesToAdd.length > 0) {
      message.success(`${filesToAdd.length} file(s) added to the upload list.`);
    }
  };

  const removeFile = (fileToRemove) => {
    setFiles(prevFiles => prevFiles.filter(f => f.file !== fileToRemove));
  };

  const updateFileStatus = (file, status, progress = 0, error = null, url = null) => {
    setFiles(prevFiles => prevFiles.map(f => 
      f.file === file ? { ...f, status, progress, error, url } : f
    ));
  };

  const retryUpload = (file) => {
    updateFileStatus(file, 'pending', 0, null, null);
  };

  return (
    <FileContext.Provider value={{ files, addFiles, removeFile, updateFileStatus, retryUpload }}>
      {children}
    </FileContext.Provider>
  );
};

export const useFileContext = () => useContext(FileContext);