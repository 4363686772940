// components/UploadZone.js
import React, { useRef } from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useFileContext } from '../contexts/FileContext';

const { Dragger } = Upload;

const UploadZone = () => {
  const { addFiles } = useFileContext();
  const processedFiles = useRef(new Set());

  const handleChange = (info) => {
    const { fileList } = info;
    const newFiles = fileList
      .filter(file => !processedFiles.current.has(file.uid))
      .map(file => file.originFileObj || file);
    
    if (newFiles.length > 0) {
      addFiles(newFiles);
      newFiles.forEach(file => processedFiles.current.add(file.uid));
    }
  };

  return (
    <Dragger 
      multiple={true}
      beforeUpload={() => false}
      onChange={handleChange}
      accept="image/*,video/*"
      showUploadList={false}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
      <p className="ant-upload-hint">支持图片和视频上传。单个视频或GIF最大 5MB，图片无限制但会压缩，最多 10 个文件。</p>
    </Dragger>
  );
};

export default UploadZone;